import React,{useEffect} from 'react'

const Invitation = () => {
    useEffect(() => {
      document.title = 'REFERRAL INITIALIZATION';
        let inviter = (new URLSearchParams(window.location.search)).get("referral");
        if (inviter !== '') {
            localStorage.setItem('invited-by', inviter);
            setTimeout(() => {
                return window.location.href= 'https://www.lincoln-global.com';
            }, 2000);
        }
    })
  return (
    <div></div>
  )
}

export default Invitation