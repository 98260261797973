import React, {useEffect, Fragment, useState} from 'react';
import { Link } from 'react-router-dom';
import apiClient from "../../http-common";
import { useQuery } from "react-query";

const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
const getDate = (date) => new Date(date).toDateString();
const addressText = (address) => {
    let last6 = address.slice(address.length - 11);
    let first3 = address.substring(0,3);
    return first3+'XXX'+last6;
}
const Analysis = () => {

    const { uniqueNamesGenerator, names, NumberDictionary } = require('unique-names-generator');

    const initialAnalysis = {
        todayInvestments: 0,
        todayWithdrawals: 0,
        todayReferrals: 0,
        Investments: [],        
        todayInvestmentsPercent: 0,
        todayWithdrawalsPercent: 0,
        todayReferralsPercent: 0,
        totalInvestments: 0,
        totalWithdrawals: 0,
        totalReferrals: 0
        
    }
    const [analysis, setAnalysis] = useState(initialAnalysis);
    const { isLoading: isLoadingAnalysis, refetch: getAnalysis } = useQuery(
        "account-analysis",
        async () => {
          return await apiClient.get("/api/analysis");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setAnalysis({...analysis, 
                    todayInvestments: res.data.today_investment,
                    todayWithdrawals: res.data.today_withdrawal,
                    todayReferrals: res.data.today_referral,
                    Investments: res.data.investments,                    
                    todayInvestmentsPercent: res.data.investment_percent,
                    todayWithdrawalsPercent: res.data.withraw_percent,
                    todayReferralsPercent: res.data.referral_percent,
                    totalInvestments: res.data.total_investments,
                    totalWithdrawals: res.data.total_withdrawals,
                    totalReferrals: res.data.total_referrals
                });
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      const iniVals =  {
            refs : 0,
            inivs : 0,
            withs : 0,
            per1 : 0,
            per2 : 0, 
            per3 : 0
    
            };

            const [trVals, setTrsVals] = useState(iniVals);

      useEffect(() => {
           if(window && window.init_template){
            window.init_template();

            
        }  
        getAnalysis();

        let gen = setInterval(() => {
            setTrsVals({...trVals, 
                refs: NumberDictionary.generate({ min: 100, max: 20000 }),
                inivs: NumberDictionary.generate({ min: 100, max: 1000000 }),
                withs : NumberDictionary.generate({ min: 100, max: 1000000 }),
                per1 : NumberDictionary.generate({ min: -90, max: 100 }),
                per2 : NumberDictionary.generate({ min: -10, max: 100 }),
                per3 : NumberDictionary.generate({ min: -80, max: 100 }),
            })
        }, 2000);

        return () => {
            clearInterval(gen);
        };

      }, [isLoadingAnalysis]);
      const {todayInvestments,
         todayWithdrawals,
         todayReferrals,
         Investments,
         todayInvestmentsPercent,
         todayWithdrawalsPercent,
         todayReferralsPercent,
        totalInvestments,
        totalWithdrawals,
        totalReferrals
        } = analysis;

        const  {
            refs,
            inivs,
            withs,
            per1,
            per2 ,
            per3 ,
    
            } = trVals;

  return (
    <div>
        <div className=" mb-4  mt-2">
            <div className="row gx-1 text-center">
                <div className="col-4 mb-n2">
                    <Link to="/" className="card me-0 rounded-0 shadow-sm " style={{height: "70px"}}>
                    <div className="card-top">
                            {per1 > 0 ?<p className="mb-0 font-12 color-green-dark"><span className="color-theme font-12 opacity-70">24hr </span> {Number(per1).toFixed(2)}%</p>
                             :
                             <p className="mb-0 font-12 color-red-dark"><span className="color-theme font-12 opacity-70">24hr </span> {Number(per1).toFixed(2)}%</p>}
                        </div>

                        <div className="card-center">
                            {refs > 0 ?<h4 className="pt-1 mb-1 color-green-dark">${Number(refs).toFixed(2)} </h4>
                            :<h4 className="pt-1 mb-1 color-red-dark">${Number(refs).toFixed(2)}</h4>}
                        </div>
                        <div className="card-bottom">
                            <p className="font-11 mb-0 opacity-70">Referrals </p>
                        </div>
                    </Link>
                </div>
                <div className="col-4 mb-n2">
                    <Link to="/" className="card me-0 rounded-0 shadow-sm " style={{height: "70px"}}>
                    <div className="card-top">
                            {per2 > 0 ?<p className="mb-0 font-12 color-green-dark"><span className="color-theme font-12 opacity-70">24hr </span> {Number(per2).toFixed(2)}%</p>
                             :
                             <p className="mb-0 font-12 color-red-dark"><span className="color-theme font-12 opacity-70">24hr </span> {Number(per2).toFixed(2)}%</p>}
                        </div>

                        <div className="card-center">
                            {inivs > 0 ?<h4 className="pt-1 mb-1 color-green-dark">${Number(inivs).toFixed(2)}</h4>
                            :<h4 className="pt-1 mb-1 color-red-dark">${Number(inivs).toFixed(2)}</h4>}
                        </div>
                        <div className="card-bottom">
                            <p className="font-11 mb-0 opacity-70">Investments</p>
                        </div>
                    </Link>
                </div>
                <div className="col-4 mb-n2">
                    <Link to="/" className="card me-0 rounded-0 shadow-sm " style={{height: "70px"}}>
                    <div className="card-top">
                            {per3 > 0 ?<p className="mb-0 font-12 color-green-dark"><span className="color-theme font-12 opacity-70">24hr </span> {Number(per3).toFixed(2)}%</p>
                             :
                             <p className="mb-0 font-12 color-red-dark"><span className="color-theme font-12 opacity-70">24hr </span> {Number(per3).toFixed(2)}%</p>}
                        </div>
                        <div className="card-center">
                            {withs > 0 ?<h4 className="pt-1 mb-1 color-green-dark">${Number(withs).toFixed(2)}</h4>
                            :<h4 className="pt-1 mb-1 color-red-dark">${Number(withs).toFixed(2)}</h4>}
                            
                        </div>
                        <div className="card-bottom">
                            <p className="font-11 mb-0 opacity-70">Withdrawals</p>
                        </div>
                    </Link>
                </div>	
            </div>
        </div>
       
    </div>
  )
}

export default Analysis